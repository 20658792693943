"use client";

import { FieldAttributes, useFormikContext } from "formik";

import Input from "@client/components/formik/input";

export default function FormikZipCode({
  id = "zip-code",
  name = "zipCode",
}: FieldAttributes<any>) {
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.value.replaceAll(/[^0-9]/g, "").slice(0, 5));
  };

  return (
    <Input id={id} name={name} inputMode="numeric" onChange={handleOnChange} />
  );
}
