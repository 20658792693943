import {
  EVENT_COOKIE_CONSENT_SUBMITTED,
  EVENT_COOKIE_CONSENT_VIEWED,
  EVENT_CTA_CLICKED,
  EVENT_DATA_LAYER_INITIALIZED,
  EVENT_DATA_LAYER_READY,
  EVENT_ERROR,
  EVENT_JOURNEY_STARTED,
  EVENT_JOURNEY_UPDATED,
  EVENT_LANDING_PAGE_VIEWED,
  EVENT_LEAD_FORM_SUBMITTED,
  EVENT_NPS_5,
  EVENT_PAGE_NOT_FOUND,
  EVENT_PAGE_VIEWED,
  EVENT_SECONDARY_OFFERS_FORM_SUBMITTED,
  EVENT_SECONDARY_OFFERS_STEP_SUBMITTED,
  EVENT_SECONDARY_OFFER_CLICKED,
  EVENT_STEP_SUBMITTED,
  EVENT_STEP_VIEWED,
  EVENT_THANK_YOU_PAGE_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
} from "@shared/constants";

import PubSub from "@client/classes/data-layer/pub-sub";
import type { Props as JourneyProps } from "@client/classes/models/journey";

import type EventsType from "@packages/types/events";
import type LeadForm from "@packages/types/lead-form";
import type SecondaryOffersForm from "@packages/types/secondary-offers-form";

export default class Events extends PubSub {
  /**
   * Flag used to define whether class is "ready" to fire events.
   */
  private ready: boolean = false;

  public nonEssentialScriptsReady: boolean = false;

  /**
   * Initialize the `Event` class.
   */
  constructor() {
    super();
  }

  /**
   * Route event to proper event handler method.
   *
   * @param {string} name Name of the event being fired
   * @param {unknown} data Data to pass to event handler
   */
  trigger(name: string, data?: unknown) {
    switch (name) {
      case EVENT_COOKIE_CONSENT_SUBMITTED:
        this.publishCookieConsentSubmitted(
          data as EventsType.CookieConsentSubmitted,
        );
        break;
      case EVENT_COOKIE_CONSENT_VIEWED:
        this.publishCookieConsentViewed();
        break;
      case EVENT_DATA_LAYER_INITIALIZED:
        this.publishInitialized(data as EventsType.DataLayerInitialized);
        break;
      case EVENT_DATA_LAYER_READY:
        this.publishReady();
        break;
      case EVENT_CTA_CLICKED:
        this.publishEventCTAClicked(data as EventsType.CTAClicked);
        break;
      case EVENT_ERROR:
        this.publishError(data as EventsType.ErrorData);
        break;
      case EVENT_JOURNEY_STARTED:
        this.publishJourneyStarted(data as JourneyProps);
        break;
      case EVENT_JOURNEY_UPDATED:
        this.publishJourneyUpdated();
        break;
      case EVENT_LANDING_PAGE_VIEWED:
        this.publishLandingPageView();
        break;
      case EVENT_STEP_VIEWED:
        this.publishLeadFormStepView(data as LeadForm.Step);
        break;
      case EVENT_STEP_SUBMITTED:
        this.publishLeadFormStepSubmitted(data as LeadForm.StepValues);
        break;
      case EVENT_LEAD_FORM_SUBMITTED:
        this.publishLeadFormSubmitted(data as EventsType.LeadFormSubmitted);
        break;
      case EVENT_PAGE_NOT_FOUND:
        this.publishPageNotFound();
        break;
      case EVENT_PAGE_VIEWED:
        this.publishPageView();
        break;
      case EVENT_SECONDARY_OFFER_CLICKED:
        this.publishSecondaryOfferClicked(
          data as EventsType.SecondaryOfferClicked,
        );
        break;
      case EVENT_SECONDARY_OFFERS_FORM_SUBMITTED:
        this.publishSecondaryOffersFormSubmitted(
          data as EventsType.SecondaryOffersFormSubmitted,
        );
        break;
      case EVENT_SECONDARY_OFFERS_STEP_SUBMITTED:
        this.publishSecondaryOffersStepSubmitted(
          data as SecondaryOffersForm.StepValues,
        );
        break;
      case EVENT_THANK_YOU_PAGE_VIEWED:
        this.publishThankYouPageView(data as EventsType.ThankYouPage);
        break;
      case EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS:
        this.publishTriggerNonEssentialScripts();
        break;
      case EVENT_NPS_5:
        this.publishNPS5(data as EventsType.NPSRating);
      default:
        this.publish(name, data);
        break;
    }
  }

  /**
   * Publish event after "Data Layer Initialized" is published to prevent race conditions.
   *
   * @param {string} name Name of the event being fired
   * @param {unknown} data Data to pass to event handler
   */
  queue(name: string, data?: unknown) {
    if (this.ready) {
      this.trigger(name, data);
    } else {
      this.subscribe(EVENT_DATA_LAYER_READY, () => this.trigger(name, data));
    }
  }

  /**
   * Handle "Cookie Consent Submitted" event logic
   */
  private publishCookieConsentSubmitted(
    data: EventsType.CookieConsentSubmitted,
  ) {
    this.publish(EVENT_COOKIE_CONSENT_SUBMITTED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Cookie Consent Viewed" event logic
   */
  private publishCookieConsentViewed() {
    this.publish(EVENT_COOKIE_CONSENT_VIEWED, window.location);
  }

  private publishEventCTAClicked(data: EventsType.CTAClicked) {
    this.publish(EVENT_CTA_CLICKED, {
      data,
      location: window.location,
    });
  }
  /**
   * Handle "Landing Page View" event logic
   */
  private publishError(data: EventsType.ErrorData) {
    this.publish(EVENT_ERROR, { data, location: window.location });
  }

  /**
   * Handle "Data Layer Initialized" event logic
   */
  private publishInitialized(data: EventsType.DataLayerInitialized) {
    this.publish(EVENT_DATA_LAYER_INITIALIZED, data);
  }

  /**
   * Handle "Journey Started" event logic
   */
  private publishJourneyStarted(data: JourneyProps) {
    this.publish(EVENT_JOURNEY_STARTED, data);
  }

  /**
   * Handle "Journey Updated" event logic
   */
  private publishJourneyUpdated() {
    this.publish(EVENT_JOURNEY_UPDATED);
  }

  /**
   * Handle "Landing Page View" event logic
   */
  private publishLandingPageView() {
    this.publish(EVENT_LANDING_PAGE_VIEWED, window.location);
  }

  /**
   * Handle "Landing Page View" event logic
   */
  private publishLeadFormStepView(data: LeadForm.Step) {
    this.publish(EVENT_STEP_VIEWED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Step Submit" event logic
   */
  private publishLeadFormStepSubmitted(data: LeadForm.StepValues) {
    // DO NOT send "cypress" key to Front-End API
    delete data["cypress"];

    this.publish(EVENT_STEP_SUBMITTED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Lead Form Submit" event logic
   */
  private publishLeadFormSubmitted(data: EventsType.LeadFormSubmitted) {
    this.publish(EVENT_LEAD_FORM_SUBMITTED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Page Not Found" event logic
   */
  private publishPageNotFound() {
    this.publish(EVENT_PAGE_NOT_FOUND, window.location);
  }

  /**
   * Handle "Page View" event logic
   */
  private publishPageView() {
    this.publish(EVENT_PAGE_VIEWED, window.location);
  }

  /**
   * Handle "Data Layer Ready" event logic
   */
  private publishReady() {
    this.ready = true;
    this.publish(EVENT_DATA_LAYER_READY);
  }

  /**
   * Handle "Page View" event logic
   */
  private publishSecondaryOfferClicked(data: EventsType.SecondaryOfferClicked) {
    this.publish(EVENT_SECONDARY_OFFER_CLICKED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Secondary Offers Step Submit" event logic
   */
  private publishSecondaryOffersStepSubmitted(
    data: SecondaryOffersForm.StepValues,
  ) {
    // DO NOT send "cypress" key to Front-End API
    delete data["cypress"];

    this.publish(EVENT_SECONDARY_OFFERS_STEP_SUBMITTED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Secondary Offer Form Submit" event logic
   */
  private publishSecondaryOffersFormSubmitted(
    data: EventsType.SecondaryOffersFormSubmitted,
  ) {
    this.publish(EVENT_SECONDARY_OFFERS_FORM_SUBMITTED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Thank You Page View" event logic
   */
  private publishThankYouPageView(data: EventsType.ThankYouPage) {
    this.publish(EVENT_THANK_YOU_PAGE_VIEWED, {
      data,
      location: window.location,
    });
  }

  /**
   * Handle "Trigger Non-Essential Scripts" event logic
   */
  private publishTriggerNonEssentialScripts() {
    this.nonEssentialScriptsReady = true;
    this.publish(EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS);
  }

  private publishNPS5(data: EventsType.NPSRating) {
    this.publish(EVENT_NPS_5, {
      data,
      location: window.location,
    });
  }
}
