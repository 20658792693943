import {
  BRAZE,
  ENVIRONMENT,
  EVENT_NPS_5,
  EVENT_THANK_YOU_PAGE_VIEWED,
  EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
  PRODUCTION,
} from "@shared/constants";

import { logDebug, logError } from "@shared/functions/log";

import type DataLayer from "@client/classes/data-layer/data-layer";
import type Journey from "@client/classes/models/journey";
import type User from "@client/classes/models/user";

import type Event from "@packages/types/event";
import type Events from "@packages/types/events";

type Braze = {
  logCustomEvent: Function;
};

type Window = typeof window & {
  braze: Braze;
};

export const THANK_YOU_PAGE_EVENT = "ThankYouView";
export const NPS_5_EVENT = "NPS5";

export default class BrazeEvents {
  /**
   * Reference to the `DataLayer` class.
   */
  readonly dataLayer: typeof DataLayer;

  /**
   * Initialize `BrazeEvents` class
   */
  constructor(dataLayer: typeof DataLayer) {
    this.dataLayer = dataLayer;

    this.dataLayer.events.subscribe(
      EVENT_THANK_YOU_PAGE_VIEWED,
      this.handleThankYouPage.bind(this),
    );

    this.dataLayer.events.subscribe(EVENT_NPS_5, this.handleNPS5.bind(this));

    logDebug({ message: "Class 'BrazeEvents' initialized" });
  }

  /**
   * Send "Thank You Page" data
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data
   */
  private handleThankYouPage(
    _: string,
    { data }: Event.Data<Events.ThankYouPage>,
  ) {
    this.send(THANK_YOU_PAGE_EVENT, {
      disposition: data.leadSubmission?.data?.offers?.[0]?.provider,
      email:
        this.dataLayer.user.getProp<User, "email">("email") ||
        data.leadSubmission?.data?.applicant?.email,
      cookieId:
        this.dataLayer.user.getProp<User, "uuid">("uuid") ||
        data.leadSubmission?.cookie_id,
      softpullDebtAmount:
        data.leadSubmission?.data?.soft_pull_data?.debt_amount,
      submittedDebtAmount:
        this.dataLayer.user.getProp<User, "debtAmount">("debtAmount") ||
        data.leadSubmissionDraft?.data?.debtAmount,
      monthlyPayment: data.leadSubmission?.data?.adr_monthly_payment,
      monthlySavings: data.leadSubmission?.data?.adr_monthly_savings,
      journey:
        this.dataLayer.journey.getProp<Journey, "name">("name") || data.journey,
    });
  }

  private handleNPS5(_: string, { data }: Event.Data<Events.NPSRating>) {
    this.send(NPS_5_EVENT, {
      experienceRating: data?.experience_rating,
      leadSubmissionCookieId: data?.lead_submission_cookie_id,
    });
  }

  /**
   * Send event data to Braze
   *
   * @param {string} event Name of event being fired
   * @param {object} data Event data to send
   */
  private send(event: string, data?: Object) {
    const brazeEvent = () => {
      if (ENVIRONMENT === PRODUCTION) {
        try {
          (window as Window).braze.logCustomEvent(event, data);
        } catch (error) {
          logError({
            message: "Braze function braze.logCustomEvent() failed.",
            error,
            event: {
              name: event,
              meta: { data },
              destination: BRAZE,
            },
          });
        }
      }

      this.dataLayer.events.log(event, data || {}, BRAZE);
    };

    if (this.dataLayer.events.nonEssentialScriptsReady) {
      brazeEvent();
    } else {
      this.dataLayer.events.subscribe(
        EVENT_TRIGGER_NON_ESSENTIAL_SCRIPTS,
        () => {
          brazeEvent();
        },
      );
    }
  }
}
