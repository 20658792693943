import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";

import { ButtonProps } from "@ui/js/components/button";

export type Props = ButtonProps & {
  chevron?: boolean;
  description?: string;
  loading?: boolean;
  submit?: boolean;
  type?: string;
};

export default function FormButton({
  chevron,
  color,
  description,
  iconLeft,
  iconRight,
  loading,
  submit,
  text,
  type,
  variant,
  ...props
}: Props) {
  if (chevron) {
    iconRight = <FontAwesomeIcon icon={faChevronRight} />;
  }

  if (loading) {
    iconRight = <FontAwesomeIcon icon={faSpinner} spin />;
  }

  return (
    <button
      type={type || "submit"}
      className={clsx({
        form__button: true,
        "form__button--is-primary": color === "primary",
        "form__button--is-secondary": color === "secondary",
        "form__button--is-outlined": variant === "outlined",
        "form__button--is-solid": variant === "solid",
        "form__button--handles-submit": submit === true,
      })}
      {...props}
    >
      {iconLeft && <span className="form__button__icon">{iconLeft}</span>}
      {description ? (
        <>
          <span className="form__button__text">
            <span
              className="form__button__label"
              dangerouslySetInnerHTML={{ __html: text || "" }}
            />
            <span className="form__button__description">{description}</span>
          </span>
        </>
      ) : (
        <span
          className="form__button__label"
          dangerouslySetInnerHTML={{ __html: text || "" }}
        />
      )}
      {iconRight && (
        <span
          // TODO: fix this
          className={`form__button__icon ${loading || type == "button" ? "form__button__icon--is-loading" : ""}`}
        >
          {iconRight}
        </span>
      )}
    </button>
  );
}
