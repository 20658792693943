/**
 * Converts a string in kebab-case to camel case (camelCase).
 *
 * @param string - The string to convert.
 * @returns The string in camel case.
 */
export default function kebabToCamelCase(string: string) {
  return string
    .replace(/^-+/, "")
    .replace(/-+$/, "")
    .replace(/-+([a-z0-9])/gi, (_, char) => char.toUpperCase());
}
