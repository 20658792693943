import * as Yup from "yup";

const maxAge = 100;
const minAge = 18;
const today = new Date(new Date().setHours(0, 0, 0, 0));

export type DefaultFieldValues = typeof defaultFieldValues;

export const defaultFieldValues = {
  "butter-cms-editable": {
    butterCMSEditable: "",
  },
  city: {
    city: "",
  },
  "credit-score": {
    creditScore: "",
  },
  "debt-amount": {
    debtAmount: "",
  },
  dob: {
    dob: "",
  },
  email: {
    agreesToDataSharing: "true",
    agreesToSoftPull: "true",
    agreesToTCPA: "true",
    email: "",
    validEmail: "",
  },
  "financial-goal": {
    financialGoal: "",
  },
  "first-responder-type": {
    firstResponderType: "",
  },
  "income-amount": {
    incomeAmount: "",
  },
  "loan-application-history": {
    loanApplicationHistory: "",
  },
  "loan-timing": {
    loanTiming: "",
  },
  "loan-amount": {
    loanAmount: "",
  },
  "military-branch": {
    militaryBranch: "",
  },
  "military-branch-thank-you": {
    militaryBranch: "",
  },
  name: {
    firstName: "",
    lastName: "",
  },
  "nurse-specialty": {
    nurseSpecialty: "",
  },
  "payment-situation": {
    paymentSituation: "",
  },
  "phone-number": {
    agreesToTCPA: "true",
    phoneNumber: "",
  },
  "reason-enlisted": {
    reasonEnlisted: "",
  },
  "reason-enlisted-thank-you": {
    reasonEnlisted: "",
  },
  state: {
    state: "",
  },
  "street-address": {
    streetAddress: "",
  },
  "student-loan-debt": {
    studentLoanDebt: "",
  },
  "union-type": {
    unionType: "",
  },
  "teaching-level": {
    teachingLevel: "",
  },
  "zip-code": {
    zipCode: "",
  },
};

export const validationSchema = {
  "butter-cms-editable": Yup.object().shape({
    butterCMSEditable: Yup.string().required("Please select an option"),
  }),
  city: Yup.object().shape({
    city: Yup.string()
      .min(3)
      .matches(/[^0-9]/, "Please enter your city")
      .required("Please enter your city"),
  }),
  "credit-score": Yup.object().shape({
    creditScore: Yup.string().required("Please select an option"),
  }),
  dob: Yup.object().shape({
    dob: Yup.string()
      .transform((value) => {
        const [MM, DD, YYYY] = value.split("/").map((s: string) => s);
        let year = Number(YYYY);

        // Allow 2-digit year entry
        if (YYYY && YYYY.length === 2) {
          year += Number(YYYY) > today.getFullYear() - 2000 ? 1900 : 2000;
        }

        if (year) {
          value = value.replace(YYYY, year);
        }

        return value;
      })
      .test(
        "dob-format",
        "Please enter your date of birth using the format: MM/DD/YYYY",
        (value) => {
          if (value) {
            const [MM, DD, YYYY] = value.split("/").map((s) => Number(s));

            if (
              String(YYYY).length < 4 ||
              MM > 12 ||
              MM < 1 ||
              DD > 31 ||
              DD < 1
            ) {
              return false;
            }
          }

          return true;
        },
      )
      .test(
        "dob-min-age",
        `You must be at least ${minAge} years old`,
        (value) => {
          if (value) {
            const [MM, DD, YYYY] = value.split("/").map((s) => Number(s));

            if (new Date(YYYY + minAge, MM - 1, DD) > today) {
              return false;
            }
          }

          return true;
        },
      )
      .test(
        "dob-max-age",
        `You must be less than ${maxAge} years old`,
        (value) => {
          if (value) {
            const [MM, DD, YYYY] = value.split("/").map((s) => Number(s));

            if (new Date(YYYY + maxAge, MM - 1, DD) <= today) {
              return false;
            }
          }

          return true;
        },
      )
      .min(8, "Please enter your date of birth")
      .max(10, "Please enter your date of birth")
      .matches(
        /(\d{2}\/\d{2}\/\d{4})/,
        "Please enter your date of birth using the format: MM/DD/YYYY",
      )
      .required("Please enter your date of birth"),
  }),
  "debt-amount": Yup.object().shape({
    debtAmount: Yup.string().required("Please select an option"),
  }),
  email: Yup.object().shape({
    agreesToDataSharing: Yup.string().required(),
    agreesToSoftPull: Yup.string().required(),
    agreesToTCPA: Yup.string().required(),
    email: Yup.string()
      .email("Please enter your email address")
      .matches(/(\w@\w{2,}\.\w{2,})/, "Please enter your email address")
      .required("Please enter your email address"),
    // validEmail: Yup.string().required(),
  }),
  "financial-goal": Yup.object().shape({
    financialGoal: Yup.string().required("Please select an option"),
  }),
  "first-responder-type": Yup.object().shape({
    firstResponderType: Yup.string().required("Please select an option"),
  }),
  "income-amount": Yup.object().shape({
    incomeAmount: Yup.string()
      .test(
        "income-amount-valid",
        "Please enter a number between $1 and $10,000,000",
        (value) => {
          if (value) {
            const amount = Number((value as string).replace(/[^0-9.-]+/g, ""));

            if (!value || amount <= 0 || amount > 10000000) {
              return false;
            }
          }

          return true;
        },
      )
      .required("Please enter a number between $1 and $10,000,000"),
  }),
  "loan-amount": Yup.object().shape({
    loanAmount: Yup.string()
      .test(
        "loan-amount-valid",
        "Please enter a number between $1 and $10,000,000",
        (value) => {
          if (value) {
            const amount = Number((value as string).replace(/[^0-9.-]+/g, ""));

            if (!value || amount <= 0 || amount > 10000000) {
              return false;
            }
          }

          return true;
        },
      )
      .required("Please enter a number between $1 and $10,000,000"),
  }),
  "loan-application-history": Yup.object().shape({
    loanApplicationHistory: Yup.string().required("Please select an option"),
  }),
  "loan-timing": Yup.object().shape({
    loanTiming: Yup.string().required("Please select an option"),
  }),
  "military-branch": Yup.object().shape({
    militaryBranch: Yup.string().required(
      "Please select a U.S. military branch",
    ),
  }),
  "military-branch-thank-you": Yup.object().shape({
    militaryBranch: Yup.string().required(
      "Please select a U.S. military branch",
    ),
  }),
  name: Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
  }),
  "nurse-specialty": Yup.object().shape({
    nurseSpecialty: Yup.string().required("Please select a specialty"),
  }),
  "payment-situation": Yup.object().shape({
    paymentSituation: Yup.string().required("Please select an option"),
  }),
  "phone-number": Yup.object().shape({
    agreesToTCPA: Yup.string().required(),
    phoneNumber: Yup.string()
      .transform((value) => {
        return value.replace(/[^0-9]/g, "");
      })
      .min(10, "Please enter your phone number")
      .max(10, "Please enter your phone number")
      .test("phone-number-valid", "Please enter your phone number", (value) => {
        if (value) {
          const phone = Number(value);

          if (phone < 2010000000 || phone > 9899999999) {
            return false;
          }
        }

        return true;
      })
      .required("Please enter your phone number"),
  }),
  "reason-enlisted": Yup.object().shape({
    reasonEnlisted: Yup.string().required("Please select a reason"),
  }),
  "reason-enlisted-thank-you": Yup.object().shape({
    reasonEnlisted: Yup.string().required("Please select a reason"),
  }),
  state: Yup.object().shape({
    state: Yup.string()
      .min(2, "Please select your state")
      .max(2, "Please select your state")
      .required("Please select your state"),
  }),
  "street-address": Yup.object().shape({
    streetAddress: Yup.string()
      .min(3, "Please enter your street address")
      .matches(/[0-9]\s/, "Please enter your street address")
      .matches(/[a-zA-Z]/, "Please enter your street address")
      .required("Please enter your street address"),
  }),
  "student-loan-debt": Yup.object().shape({
    studentLoanDebt: Yup.string().required("Please select an option"),
  }),
  "union-type": Yup.object().shape({
    unionType: Yup.string().required("Please select an industry or profession"),
  }),
  "teaching-level": Yup.object().shape({
    teachingLevel: Yup.string().required("Please select a level"),
  }),
  "zip-code": Yup.object().shape({
    zipCode: Yup.string()
      .matches(/^[0-9]+$/, "Please enter your 5-digit zip code")
      .min(5, "Please enter your 5-digit zip code")
      .max(5, "Please enter your 5-digit zip code")
      .required("Please enter your 5-digit zip code"),
  }),
};
