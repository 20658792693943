"use client";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Phone from "@client/components/formik/phone-number";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "phone-number";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function PhoneNumber() {
  return (
    <>
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Fieldset
        dialog="Your phone number enables direct communication for timely updates and support regarding your debt solutions.  We ask this so that we, our partners and their affiliated companies can contact you about the products and services you inquired about, even if your telephone number is listed on any Do-Not Call list. Contact may be made through automatic dialing systems, artificial or prerecorded voice messaging, or text message."
        dialogHeader="Why do we need your phone number?"
        legend="What is your phone number?"
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Phone Number"
        >
          <Phone id={INPUT_ID_AND_LABEL_HTMLFOR} name={FIELD_NAME_SELECTOR} />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
