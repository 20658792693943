"use client";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Zip from "@client/components/formik/zip-code";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "zip-code";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function ZipCode() {
  return (
    <>
      <Fieldset
        dialog="We ask for your zip code to consider regional factors in your debt consolidation choices. Different areas have different lending rules, rates, and services. Your zip code helps us give you advice and options that match your location's financial landscape, making our recommendations more relevant to you."
        legend="What is your zip code?"
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Zip Code"
        >
          <Zip id={INPUT_ID_AND_LABEL_HTMLFOR} name={FIELD_NAME_SELECTOR} />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
