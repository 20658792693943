"use client";

import {
  faCircleChevronDown,
  faCircleChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useState } from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  label: string;
  open?: boolean;
};

export default function Accordion({
  children,
  className,
  label,
  open = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <details className={clsx("accordion", className)}>
      <summary className="accordion__summary">
        <span className="accordion__summary__icon">
          <FontAwesomeIcon
            icon={isOpen ? faCircleChevronUp : faCircleChevronDown}
            onClick={() => setIsOpen(!isOpen)}
            data-testid="accordion-icon"
          />
        </span>
        <span
          className="accordion__summary__text"
          onClick={() => setIsOpen(!isOpen)}
        >
          {label}
        </span>
      </summary>
      <div className="accordion__content">{children}</div>
    </details>
  );
}
