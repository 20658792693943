"use client";

import { useFormikContext } from "formik";

import Button, {
  Props as FormButtonProps,
} from "@ui/js/components/form/button";

export default function LeadFormButton(props: FormButtonProps) {
  const { isSubmitting, values } = useFormikContext<any>();

  let loading = false;
  let text = "Continue";

  if (values.submitMethod === "submit") {
    text = "Submit";
  }

  if (isSubmitting) {
    loading = true;
  }

  return (
    <Button
      loading={loading}
      disabled={loading}
      submit={true}
      text={text}
      variant="solid"
      {...props}
    />
  );
}
