import React from "react";

export default function Fieldset({
  children,
  legend,
}: {
  children: React.ReactNode;
  legend: string | React.ReactNode;
}) {
  return (
    <fieldset className="form__fieldset">
      <legend className="form__legend">{legend}</legend>
      {children}
    </fieldset>
  );
}
