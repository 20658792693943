"use client";

import { useFormikContext } from "formik";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "union-type";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function UnionType() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(FIELD_NAME_SELECTOR, e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="Which industry or profession is your union associated with?">
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Union Type"
        >
          <Select
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            name={FIELD_NAME_SELECTOR}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select an Industry or Profession
            </option>
            <option value="construction_and_trades">
              Construction and Trades
            </option>
            <option value="government_and_public_administration">
              Government and Public Administration
            </option>
            <option value="transportation">Transportation</option>
            <option value="public_safety">Public Safety</option>
            <option value="manufacturing_and_production">
              Manufacturing and Production
            </option>
            <option value="education">Education</option>
            <option value="healthcare">Healthcare</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
