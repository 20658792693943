import clsx from "clsx";
import React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  children: React.ReactNode;
  pageName: string;
};

export default function Main({
  children,
  className,
  pageName,
  ...props
}: Props) {
  return (
    <main
      id="site-main"
      className={clsx(`site-main`, className)}
      data-spec-page={pageName}
      {...props}
    >
      {children}
    </main>
  );
}
