import clsx from "clsx";
import React from "react";

type Props = React.LabelHTMLAttributes<HTMLLabelElement> & {
  label: string;
};

export default function label({
  htmlFor,
  label,
  className = "",
  ...props
}: Props) {
  return (
    <label
      data-testid="test-label"
      htmlFor={htmlFor}
      className={clsx("form__label", className)}
      {...props}
    >
      {label}
    </label>
  );
}
