import clsx from "clsx";
import React from "react";

import camelCaseToKebab from "@shared/functions/camelCaseToKebab";

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
  name: string;
};

export default function Input({ className = "", type, ...props }: Props) {
  type = type || "text";

  if (!props.id) {
    props.id = camelCaseToKebab(props.name);
  }

  return (
    <input
      type={type}
      className={clsx(`form__input form__input_type_${type}`, className)}
      required={true}
      {...props}
    />
  );
}
