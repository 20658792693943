"use client";

import { useFormikContext } from "formik";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "first-responder-type";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function FirstResponderType() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(FIELD_NAME_SELECTOR, e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="Thank you for keeping us safe. What type of first responder are you?">
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Type of first responder"
        >
          <Select
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            name={FIELD_NAME_SELECTOR}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select an Option
            </option>
            <option value="Police_Officer">Police Officer</option>
            <option value="Firefighter">Firefighter</option>
            <option value="Paramedic_or_EMT">Paramedic or EMT</option>
            <option value="Search_and_Rescue">Search and Rescue</option>
            <option value="Disaster_Response">Disaster Response</option>
            <option value="Other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
