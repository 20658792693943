"use client";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import { useCMSOverride } from "@client/functions/custom-hooks/useCMSOverride";

type Props = {
  legendOverride?: string;
};

const INPUT_ID_AND_LABEL_HTMLFOR = "street-address";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function StreetAddress({ legendOverride }: Props) {
  const legendFallback = "What is your street address?";
  const legend = useCMSOverride(legendFallback, legendOverride);

  return (
    <>
      <Fieldset
        dialog="We request your address for three main reasons: to verify your identity, to conduct an accurate soft credit pull without affecting your score, and to match you with the best financial solutions. Your privacy is paramount to us; all data is encrypted and used with the utmost care."
        legend={legend}
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Street Address"
        >
          <Input id={INPUT_ID_AND_LABEL_HTMLFOR} name={FIELD_NAME_SELECTOR} />
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
