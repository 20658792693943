import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import camelCaseToKebab from "@shared/functions/camelCaseToKebab";

type Props = Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "name"> & {
  children: React.ReactNode;
  name: string;
};

export default function Select({ children, ...props }: Props) {
  if (!props.id) {
    props.id = camelCaseToKebab(props.name);
  }

  return (
    <div className="select-wrapper">
      <select className="form__input" required {...props}>
        {children}
      </select>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  );
}
