import clsx from "clsx";
import React from "react";

import Label from "@ui/js/components/form/label";

type Props = {
  children: React.ReactNode;
  className: string;
  id?: string;
  label?: string;
  message?: string | TrustedHTML;
  state?: "info" | "warning" | "error";
};

export default function Field({
  children,
  id,
  className,
  label,
  message,
  state,
}: Props) {
  return (
    <div
      className={clsx(
        {
          form__field: true,
          form__field_state_error: state === "error",
          form__field_state_info: state === "info",
          form__field_state_warning: state === "warning",
        },
        className,
      )}
    >
      {label && <Label htmlFor={id} label={label} />}
      {children}
      {message && (
        <span
          className="form__field__message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
}
