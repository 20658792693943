"use client";

import { useFormikContext } from "formik";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "military-branch";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function MilitaryBranch() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(FIELD_NAME_SELECTOR, e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="What branch of the United States military did you or your loved one serve in?">
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="U.S. Military Branch"
        >
          <Select
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            name={FIELD_NAME_SELECTOR}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select a branch
            </option>
            <option value="army">U.S. Army</option>
            <option value="navy">U.S. Navy</option>
            <option value="air_force">U.S. Air Force</option>
            <option value="marine_corps">U.S. Marine Corps</option>
            <option value="coast_guard">U.S. Coast Guard</option>
            <option value="national_guard">U.S. National Guard</option>
            <option value="space_force">U.S. Space Force</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
