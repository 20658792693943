/**
 * Converts a camelCase string to a kebab-case string.
 *
 * @param string - The camelCase string to convert.
 * @returns The converted kebab-case string.
 */
export default function camelCaseToKebab(str: string) {
  return str
    .replace(/([a-z0-9])([A-Z]+)([A-Z][a-z])/g, "$1-$2-$3")
    .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
    .replace(/^[A-Z]/, (char) => char.toLowerCase())
    .toLowerCase();
}
