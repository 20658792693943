"use client";

import { useFormikContext } from "formik";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";

import Field from "@client/components/formik/field";
import Select from "@client/components/formik/select";
import Button from "@client/components/lead-form/components/button";

import Fieldset from "@ui/js/components/form/fieldset";

const INPUT_ID_AND_LABEL_HTMLFOR = "reason-enlisted";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function ReasonEnlisted() {
  const { setFieldValue } = useFormikContext();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue(FIELD_NAME_SELECTOR, e.currentTarget.value);
  };

  return (
    <>
      <Fieldset legend="Thank you for your service. Why did you enlist?">
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Reason Enlisted"
        >
          <Select
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            name={FIELD_NAME_SELECTOR}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select a reason
            </option>
            <option value="patriotism">Patriotism</option>
            <option value="family_tradition">Family Tradition</option>
            <option value="education_career">
              Educational/Career Opportunities
            </option>
            <option value="adventure_travel">Adventure/Travel</option>
            <option value="personal_growth">Personal Growth</option>
            <option value="making_a_difference">Making a Difference</option>
            <option value="other">Other</option>
          </Select>
        </Field>
      </Fieldset>
      <Button />
    </>
  );
}
